<template>
  <Modal :id="id" :modalTitle="modalTitle" :class="classes" :class_body="class_body">
    <form @submit.prevent="submit()" autocomplete="off">
      <table class="table">
        <thead>
          <th class="border-top-0 pt-0">Crypto</th>
          <th class="border-top-0 pt-0">Quantity</th>
        </thead>
        <tbody>
          <tr v-for="(v, index) in v$.myCryptosListAux.$model" v-bind:key="index">
            <td class="align-middle">
              <span class="mr-2"><img :src="v$.myCryptosListAux.$model[index].img" width="20"/></span>
              <span>{{ v$.myCryptosListAux.$model[index].symbol }}</span>
              <input type="hidden" id="crypto" v-model.trim.lazy="v$.myCryptosListAux.$model[index].symbol">
            </td>
            <td>
              <div class="form-group mb-0 d-inline-block w-75">
                <input min="0" step="any" type="number" class="form-control" id="quantity" v-model.trim.lazy="v$.myCryptosListAux.$model[index].owned" @change="changeValue(index,v$.myCryptosListAux.$model[index].owned)">
                <div v-if="v.owned.$dirty">
                  <small class="form-text text-danger" v-if="v.owned.required.$invalid">Required Field</small>
                  <small class="form-text text-danger" v-if="v.owned.numeric.$invalid">Value must be a number</small>
                  <small class="form-text text-danger" v-if="v.owned.minValue.$invalid">Value must be greater than 0</small>
                </div>
              </div>
              <a class="ml-3" href="javascript:void(0)" title="Delete crypto" @click="deleteCrypto(index)"><font-awesome-icon :icon="['fas', 'times']" /></a>
            </td>
          </tr>
          <tr>
            <td>
              <VueNextSelect
                class="form-control"
                v-model="v$.newCrypto.$model"
                :options="cryptosAvailablesList"
                label-by="symbol"
                searchable="true"
                clear-on-close="true"
                close-on-select
                >
                <template #label="{ selected }">
                  <template v-if="selected">
                    <div><img class="mr-2" width="20" :src="selected.img"/>{{ selected.symbol }} - {{ selected.name }}</div>
                  </template>
                  <template v-else>Select option</template>
                </template>
                <template #dropdown-item="{ option }">
                  <div><img class="mr-2" width="20" :src="option.img"/>{{ option.symbol }} - {{ option.name }}</div>
                </template>
              </VueNextSelect>
            </td>
            <td>
              <div class="d-inline-block w-75">
                <input min="0" step="any" type="number" class="form-control" id="quantity" v-model.trim.lazy="v$.newCryptoQuantity.$model">
              </div>
              <a class="ml-3" href="javascript:void(0)" title="Add crypto" @click="addNewCrypto()"><font-awesome-icon :icon="['fas', 'plus']" /></a>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row mt-3">
        <div class="col-12">
          <button class="btn btn-primary float-right" v-bind:class="{ disabled: v$.$invalid }">
            <span v-if="!loading">Save</span>
            <img v-if="loading" src="@/assets/loader.svg" height="20" alt="">
          </button>
        </div>
      </div>
    </form>
  </Modal>
</template>

<script>

import Modal from '../../../../Shared/Modal.vue';
import axios from 'axios';
import useVuelidate from '@vuelidate/core';
import { required, numeric, minValue, helpers } from '@vuelidate/validators'
import VueNextSelect from 'vue-next-select';

export default {
  name: 'EditMyCryptos',
  props: [
    'id',
    'modalTitle',
    'class',
    'MyCryptosList',
    'index',
    'class_body'
  ],
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      classes: this.class,
      cryptosAvailablesList: [],
      myCryptosListAux: [],
      newCrypto: '',
      newCryptoQuantity: '',
      loading: false
    };
  },
  created() {
  },
  updated() {
    axios.get("/cryptos/availables-cryptos")
      .then((response)=> {
        this.cryptosAvailablesList = response.data.body;
      });
    this.myCryptosListAux = [];
    this.myCryptosListAux = this.myCryptosListAux.concat(this.MyCryptosList);
  },
  validations() {
    return {
      myCryptosListAux: {
        $each: helpers.forEach({
          symbol: {
            required,
          },
          owned: {
            required,
            numeric,
            minValue: minValue(-0.999999999)
          }
        }),
      },
      newCrypto: {
      },
      newCryptoQuantity: {
      }
    } 
  },
  components: {
    Modal,
    VueNextSelect
  },
  methods: {

    submit() {
      this.loading = true;
      const bodyToSend = this.myCryptosListAux;
      axios.put(`/cryptos/user/my-cryptos`,bodyToSend)
        .then(()=> {
          this.loading = false;
          this.closeModal();
          this.$emit('userCryptosEdited');
        });
    },
    addNewCrypto(){
      let newCryptoToAdd = {
        id_crypto: parseInt(this.newCrypto.ID),
        symbol: this.newCrypto.symbol,
        owned: parseFloat(this.newCryptoQuantity),
        img: this.newCrypto.img
      }
      this.myCryptosListAux.push(newCryptoToAdd);
      this.newCrypto = '';
      this.newCryptoQuantity = '';
    },
    deleteCrypto(i){
      this.myCryptosListAux.splice(i,1);
    },
    changeValue(index,value){
      this.myCryptosListAux[index].owned = value;
    },
    closeModal(){
      document.querySelector('#EditMyCryptos .close').click();
    }
  }
};
</script>

<style>
  legend{
    width:auto;
  }
</style>